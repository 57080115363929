// components/Home.js
import { Heading, Flex, View } from '@aws-amplify/ui-react';
export const Home = () => {
  return (
    <>
       <Heading level={4}>
        Probably just type a bunch of stuff here, or maybe not even that - yet.
      </Heading>

      <br></br>
      <Flex direction={{ base: 'column', large: 'row' }}>

     
        <View fontSize="large" width="100%"
            color={{
              base: 'black',
              small: 'black',
              medium: 'black',
              large: 'white',
              xl: 'white',
              xxl: 'white',
            }}
            backgroundColor={{
              base: 'red',
              small: 'orange',
              medium: 'yellow',
              large: 'green',
              xl: 'blue',
              xxl: 'purple',
            }}>
             
          If I were you I would visit this page and just stare at it, like watching a fire or a glacier
          <br/>
          Also, if you change the size of the screen colors will change (that's the entertainment!)
   
           
        </View>
      </Flex>
    
    </>
  );
}