// components/Login.js

import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Hub } from 'aws-amplify'
import { useNavigate } from 'react-router';
import { useContext, useEffect } from 'react';
import { DDB_User } from '../../DataServices/DDB/DDB_User'
import { AppContext } from "../../contextAPI/context";
import { schemaUserEnum_X1_PK } from '../../DataServices/DDB/DdbSchemaQueryConstants';
import { TypesContextReducers } from "../../contextAPI/reducers";



export const Login = () => {

  const navigate = useNavigate();

  const { state, dispatch } = useContext(AppContext);



  const updateUserDB = async (cogUserString: string) => {
    try {


      if (cogUserString) {

        const ddbUser = new DDB_User(cogUserString, schemaUserEnum_X1_PK.User);

        await ddbUser.updateUserDB();


        dispatch({
          type: TypesContextReducers.UpdateUser,
          payload: {
            userName: ddbUser.userName,
            userToken: ddbUser.toke,
            hasClaimedBookGroup: ddbUser.hasClaimedBookGroup,
            isAdmin: ddbUser.isAdminGroup
          }
        });


        localStorage.setItem("Goochy.userName", ddbUser.userName);


      }
    } catch (err) {
      console.log('error getting alinda:', err)

    }
  }
  const { route } = useAuthenticator((context) => [
    context.route,
    context.signOut
  ]);

  useEffect(() => {




      const unsubscribe =
        Hub.listen("auth", async ({ payload: { event, data } }) => {
          switch (event) {
            case "signIn": {

              if (data) {
                await updateUserDB(JSON.stringify(data))
              }

              break;
            }
            case "signIn_failure": {

              break;
            }
            case "signOut": {
              //Not used in form, auth checked at routing in layout
              break;
            }
            default: {

              break;
            }

          }
        });

        //Note need to listen for the route event and action after or it will 
        // interfere with the routing parent component in App.tsx 
        //Also if you listen for the state change on the react context it will happen after the route event
        // which means you won't get a true user value yet

        //Also, this allows the Auth form to show after login if the user needs to update thier email verification with a code
        //  so do not route after log in, wait until it says the user is authenticated
        if (route === 'authenticated') {

            navigate('/Home')

          }
        
    

      return unsubscribe;
    }, [route]);




  return (
    <>
      <View className="auth-wrapper">
        <Authenticator signUpAttributes={['email']}>
        </Authenticator>
      </View>


    </>
  );
}