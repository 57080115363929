//https://dev.to/elisealcala/react-context-with-usereducer-and-typescript-4obm
//https://codesandbox.io/s/context-reducer-ts-9ctis?file=/src/Products.tsx:206-404

import React, { createContext, useReducer, Dispatch } from "react";
import {
  productReducer,
  shoppingCartReducer,
  ProductActions,
  ShoppingCartActions,
  userReducer,
  UserActions,
} from "./reducers";

type ProductType = {
  id: number;
  name: string;
  price: number;
};

type UserType = {
  userName: string;
  isAdmin: boolean;
  hasClaimedBookGroup: boolean;
  userToken: string;
};

type InitialStateType = {
  products: ProductType[];
  shoppingCart: number;
  user: UserType;
};

const initialState = {
  products: [],
  shoppingCart: 0,
  user: {userName: "", isAdmin: false,hasClaimedBookGroup: false, userToken: ""}
};

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<ProductActions | ShoppingCartActions | UserActions>;
}>({
  state: initialState,
  dispatch: () => null
});

const mainReducer = (
  { products, shoppingCart, user }: InitialStateType,
  action: ProductActions | ShoppingCartActions | UserActions
) => ({
  products: productReducer(products, action),
  user: userReducer(user, action),
  shoppingCart: shoppingCartReducer(shoppingCart, action),
  
});

type prop = {
  children: React.ReactNode;
}

const AppProvider: React.FC<prop> = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
