// components/Home.js
import { Heading, Button, Flex, Image, Card, Text, useTheme, Link, useAuthenticator } from '@aws-amplify/ui-react';
import { useState } from 'react';
import alindaCoverPageImage from '../../../src/assets/AlindaCover.png';
import { CustomCognitoUser } from '../../utils/CustomCognitoUser';
import { GiveBookAway } from './GiveBookAway';


export const Buy = () => {

  const theme = useTheme();
  const { user } = useAuthenticator((context) => [context.user]);

  const [CustomCogUser, setCustomCogUser] = useState({
    userEmail: "",
    userName: "",
    toke: "",
    isAdminGroup: false,
    hasClaimedBookGroup: false


  });

  //TODO - user stuff in a central place...it's just all over now
  const isAdmin = () => {

    const cogUser = new CustomCognitoUser(JSON.stringify(user))
    setCustomCogUser(cogUser);
    


  };


  return (

    <>

      {/* https://github.com/aws-amplify/amplify-ui/blob/main/packages/react/src/primitives/Collection/utils.ts */}
      <Card variation="elevated">
        <Flex direction="row" alignItems="flex-start">

          <Flex direction="column" gap={theme.tokens.space.xs}>

            <Heading level={3} onClick={() => {
              isAdmin();
            }}>
              Alinda And The Day Between
            </Heading>
            <Text>
              It Was A Day That The Whens And The Wheres Would Be Forgotten, But Not The Day Itself.
              <br></br>
              Because Sometimes An Uncommon Day Reaches Out To Its Beginning Before It Reaches Its End,
              <br></br><br></br>
              And Hangs On.........
            </Text>
            <br></br>
            <Text
              fontSize={theme.tokens.fontSizes.large}
              color={theme.tokens.colors.font.secondary}>
              $20.00 


            </Text>

            <Text
              fontSize={theme.tokens.fontSizes.small}
              color={theme.tokens.colors.font.secondary}>
              <i>(Canadian ($CAD), Includes shipping - and - $5 to a good cause!)</i>
            </Text>
            <Link
              href="https://buy.stripe.com/fZebKc8NdgkV1j2288"
              color="#007EB9"
              isExternal={true}
            >
              <Button type="button">To Buy</Button>

            </Link>

            <br></br>
            <div>
              <Image src={alindaCoverPageImage}
                alt="Cover Page" width="7rem" />
            </div>

          </Flex>

        </Flex>

      </Card>
      <br></br>
 
      <div>{CustomCogUser.isAdminGroup ? <GiveBookAway cogUserString={JSON.stringify(user)} /> : ''}</div>

    </>
  );
}

