import { stringify } from "querystring";
import { config as appConfig } from "../../configParams";
import { CustomCognitoUser } from "../../utils/CustomCognitoUser"
import { attsItemCreation } from "./DdbSchemaQueryConstants";

//export const UserRoles = 'READ' | 'EDIT' | 'ADMIN'
export class DDB_Item {
  //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Classes

  T_PK = "Item";
  T_SK = "";
 
  X1_PK = ""; 
  X1_SK = "";
  X2_PK = ""; 
  X2_SK = "";
  X3_PK = ""; X3_SK = "";
  X4_PK: string; X4_SK: string;

  // received_type: "";
  // first_name = "";middle_name = ""; last_name = ""; 
  // country = ""; province = ""; city = ""; postal_code = "";address = "";
  // lat = ""; long = "";
  // recieved_method = ""; giverComment = ""; recieved_comment = "";
  // initiated_date = ""; recieved_date = ""; 
  // recieved_s3_url = ""; giver_s3_url = "";
  toke: string;
  userName: string;
  isAdmin: boolean;


  

// // BACKEND MAP: C:\CODE\AlindaFirstTry\Alinda-BE\Services\LambHand_Buy\POST.ts
  sent_date = '';
  sent_type = ''; //mailed, handoff, left_There,
  sent_event_id = '';
  sent_message_text_url = '';
  sent_message_file_url = '';
  sent_imgage_url = '';
  sent_comment = '';
  received_by_email = '';//BE will use gsi PK? 'ReceivedBy#Email#',
  received_by_name = '';//BE will use gsi SK? 'ReceivedBy#Nmail#',
  received_lat = '';
  received_long = '';
  received_country = '';
  received_city = '';
  received_postal_code = '';
  received_address = '';
  received_provence = '';
  received_what3words = '';
  recieved_comment = "";

  // X2_SK: 'GivenBy', //(GivenBy, SoldBy, ClaimedBy), get values from db for admin dropdown
  // X3_PK: 'Item',//Item or Claim  (db?)

  constructor(CustCogUser: CustomCognitoUser, GivenBySoldByClaimedBy: string, 

    give_buy_claim: string = 'Give',
    itemClaim: string = 'Item',
    product: string = 'AlindaAndTheDayBetween') {

    this.isAdmin = CustCogUser.isAdminGroup;

    this.userName = CustCogUser.userName;
    this.toke = CustCogUser.toke;

    this.X1_SK = give_buy_claim;

    this.X2_PK = this.userName
    this.X2_SK = `${GivenBySoldByClaimedBy}#${CustCogUser.userName}` ;
    this.X3_PK = itemClaim;
    this.X3_SK = product;
    this.X4_PK = ''//'ReceivedBy#Email#';
    this.X4_SK = ''//'ReceivedBy#Name#';
    this.sent_type = GivenBySoldByClaimedBy;


  }

  async getBuyItemDB() {
    
  }


 async createBuyItemDB() {
    try {

      const objToPass: attsItemCreation = {
        T_PK: this.T_PK, T_SK: this.T_SK, 
        X1_PK: this.X1_PK, X1_SK: this.X1_SK,
        X2_PK: this.X2_PK, X2_SK: this.X2_SK,
        X3_PK: this.X3_PK, X3_SK: this.X3_SK, 
        sent_type: this.sent_type,
      }

      if(this.X4_PK.length > 0 && this.X4_SK.length > 0){
        objToPass.X4_PK = this.X4_PK;
        objToPass.X4_SK = this.X4_SK;

      }


      if (objToPass && objToPass.T_PK && objToPass.X2_PK && this.toke && this.isAdmin) {



        const bookUrl = appConfig.api.alindaBuyBookUrl;
        console.log(`requestUrl: ${bookUrl}?BuyOrGive=${this.T_PK}`)
        console.log(`userDBToUpdate: ${JSON.stringify(objToPass)}`)
        console.log(`this.toke: ${JSON.stringify(this.toke)}`)

        const requestUrl = `${appConfig.api.alindaBuyBookUrl}?BuyOrGive=${this.T_PK}`;
        const requestResult = await fetch(
          requestUrl, {
          method: 'POST',
          headers: {
            'Authorization': this.toke
          },
          body: JSON.stringify(objToPass)
        }
        );
        const responseJSON = await requestResult.json();

        console.log("Response: " +  JSON.stringify(responseJSON))

        return responseJSON;
        
      } else {
        console.log('Buy didnt pass condition: ' + JSON.stringify(objToPass))
      }
    } catch (err) {
      console.log('error getting alinda:', err)
    }

  }


  


}