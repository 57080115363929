
////S3 APPLICATION:
////   DEV_US_EAST_1:  'http://goochymareeahh.dev.useast1.s3-website-us-east-1.amazonaws.com'
////   QA_US_EAST_1: 'http://alinda-book-app-name0ee847a80f67.s3-website-us-east-1.amazonaws.com
////  UAT_US_EAST_1: http://goochymareeahh.uat.useast1.s3-website-us-east-1.amazonaws.com
////   PROD: 'http://www.goochymareeahh.com.s3-website-us-east-1.amazonaws.com'

/// '', 'DEV_US_EAST_1' 'QA_US_EAST_1', 'PROD', 'UAT_US_EAST_1', 'UAT_AP_SOUTEAST_1'
let ENV_USED = 'PROD';


const ENV_API_ROOT_ENDPOINT = () => {

    switch(ENV_USED) { 

        case 'DEV_US_EAST_1': { 
            return 'https://272fnxlr5e.execute-api.us-east-1.amazonaws.com/prod/';
            
         } 
        case 'QA_US_EAST_1': { 
           return 'https://x5gd0tfbxl.execute-api.us-east-1.amazonaws.com/prod/';
           
        } 
        case 'UAT_US_EAST_1': { 
            return 'https://tx7dyb7rn6.execute-api.us-east-1.amazonaws.com/prod/'
            
         }  
         
        case 'UAT_AP_SOUTEAST_1': { 
            return 'https://3hdur6c872.execute-api.ap-southeast-1.amazonaws.com/prod/'
            
         }  
        case 'PROD': { 
            return 'https://0cndrq6qvl.execute-api.us-east-1.amazonaws.com/prod/'
            
         } 
        default: { 
           //statements; 
           break; 
        } 
     }
  };


  const ENV_COGNITO_IDENTITY_POOL_ID = () => {

    switch(ENV_USED) { 

        case 'DEV_US_EAST_1': { 
            return 'us-east-1:3dafe9fe-860c-41ae-a31e-2fc884484253';
            
         } 
        case 'QA_US_EAST_1': { 
           return 'us-east-1:72a7b8fd-2c61-4554-84a5-e3252f1a5bec';
            
        }  case 'UAT_US_EAST_1': { 
            return 'us-east-1:a48becc2-fbe4-4603-8f58-5ddb82946b88'
        
         } case 'UAT_AP_SOUTEAST_1': { 
            return 'us-east-1:ed22ce0c-7d3c-4adb-8d7e-a197a9395691'
        
         } 
        case 'PROD': { 
            return 'us-east-1:9cfca96a-4a99-4a12-8db5-c5a3378b638d'
        
         } 
        default: { 
           //statements; 
           break; 
        } 
     }
  };

  const ENV_COGNITO_USER_POOL = () => {

    switch(ENV_USED) { 
        case 'DEV_US_EAST_1': { 
            return '14n2djeob0ro31c44noo10aqsf';
            
         } 
        case 'QA_US_EAST_1': { 
           return '5sfgdepe07kcuj6d9mlrtcq6t3';
           
        } 
        case 'UAT_US_EAST_1': { 
            return '621ce1avshkhk2sk9obf08mmu4'
            
         }  
         case 'UAT_AP_SOUTEAST_1': { 
            return '3ni9mhiirgrjjsissbucsbp5t'
            
         } 
        case 'PROD': { 
            return '41a4vcoc3g0fpkude8mirloive'
            
         } 
        default: { 
           //statements; 
           break; 
        } 
     }
  };
  const ENV_COGNITO_USER_POOL_ID = () => {

    switch(ENV_USED) { 

        case 'DEV_US_EAST_1': { 
            return 'us-east-1_5S2ry0MI6';
            
         } 
        case 'QA_US_EAST_1': { 
           return 'us-east-1_VlqO3p4Fy';
           
        } 
        case 'UAT_US_EAST_1': { 
            return 'us-east-1_BYAZmmr34'
            
         }  
         case 'UAT_AP_SOUTEAST_1': { 
            return 'us-east-1_pTJ0zgNlq'
            
         }  
        case 'PROD': { 
            return 'us-east-1_j9pKeF21X'
            
         } 
        default: { 
           //statements; 
           break; 
        } 
     }
  };

export const config = {
    REGION: 'us-east-1',
    APP_CLIENT_ID: ENV_COGNITO_USER_POOL(),
    IDENTITY_POOL_ID: ENV_COGNITO_IDENTITY_POOL_ID(),
    USER_POOL_ID: ENV_COGNITO_USER_POOL_ID(),
    api: {
        baseUrl: ENV_API_ROOT_ENDPOINT(),
        alindaBuyBookUrl: `${ENV_API_ROOT_ENDPOINT()}/buy-book`,
        alindaClaimItem: `${ENV_API_ROOT_ENDPOINT()}/claim-item`,
        alindaUserLogin: `${ENV_API_ROOT_ENDPOINT()}/user-login`
    },



    //JUST Proof of Concept Code (not implemented)
    //UAT
    S3_PROFILE_IMAGE_BUCKET: 'alindas-books--us-east-1-0ee847a80f67',


    USER_COG_GROUP_NAME_ADMIN: 'admins',



} 