import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';

import  {Amplify } from 'aws-amplify'
import { config } from "./configParams";


//https://stackoverflow.com/questions/58220680/amplify-how-to-setup-congnito-to-use-email-as-username
Amplify.configure({
  // OPTIONAL - if your API requires authentication 
  Auth: {
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId:  config.IDENTITY_POOL_ID,
      // REQUIRED - Amazon Cognito Region
      region: config.REGION, 
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: config.USER_POOL_ID, 
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: config.APP_CLIENT_ID,
      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: 'USER_PASSWORD_AUTH'
  
  },
  API: {
      endpoints: [
          {
              name: "ApiEndpointAlinda",
              endpoint: config.api.baseUrl
          }
      ]
  }
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
root.render(<App />);
