import { config as appConfig } from "../../configParams";
import { CustomCognitoUser } from "../../utils/CustomCognitoUser"


interface iClaim {
  userName: string;
  ItemId: string;
  GlassId: string;
  userEmail: string;
  toke: string;
  isAdmin: boolean;
  hasClaimedBookGroup: boolean;

  
}


export class DDB_ClaimItem{
  //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Classes



  iClaim: iClaim | undefined;



  constructor(CognitoUserString: string, ItemId: string, GlassId: string) {

    const cogUser = new CustomCognitoUser(CognitoUserString);


    this.iClaim = {
      userName: cogUser.userName,
      ItemId: ItemId,
      GlassId: GlassId,
      userEmail: cogUser.userEmail,
      toke: cogUser.toke,
      isAdmin: cogUser.isAdminGroup,
      hasClaimedBookGroup: cogUser.hasClaimedBookGroup
    
    }

    

  }

  async getUserDB() {
    try {


      if (this.iClaim?.userName && this.iClaim?.toke) {
        const requestUrl =
          `${appConfig.api.alindaUserLogin}?userName=${this.iClaim?.userName}`;

        console.log("toke: " + this.iClaim?.toke);
        console.log("requestUrl: " + requestUrl);

        const requestResult = await fetch(requestUrl, {
          method: "GET",
          headers: {
            Authorization: this.iClaim?.toke,
          }
        });

        const responseJSON = await requestResult.json();
        console.log("responseJSON: " + JSON.stringify(responseJSON))



      }
    } catch (err) {
      console.log('error getting alinda:', err)

    }
  }

//Validate it
  async createClaim() {
    try {

      

      const userLoginURL = appConfig.api.alindaClaimItem;


      if (this.iClaim && this.iClaim.userName && 
        this.iClaim.ItemId && this.iClaim.GlassId) {
          const iClaim = this.iClaim;

          const claimToSearch = {
    
    
            userName: iClaim.userName,
            ItemId: iClaim.ItemId,
            GlassId: iClaim.GlassId,
            userEmail: iClaim.userEmail,
            isAdmin: iClaim.isAdmin,
            hasClaimedBookGroup: iClaim.hasClaimedBookGroup
    
          }




          const toke = this.iClaim.toke;
          this.iClaim.toke = "";
        const userLoginURL = appConfig.api.alindaClaimItem;
        // console.log(`-  requestUrl:  - ${userLoginURL}?logIn=${this.iClaim.userName}`)
        // console.log(` - userDBToUpdate: -  ${JSON.stringify(this.iClaim)}`)
 
        console.log('giveJSON ' + JSON.stringify(claimToSearch))
       // const requestUrl = `${appConfig.api.alindaClaimItem}?logIn=${this.iClaim.userName}`;
  

       const requestUrl = `${appConfig.api.alindaClaimItem}?logIn=${this.iClaim.userName}`;
       console.log('requestUrl ' + requestUrl)
       console.log('toke: ' + toke)

       const requestResult = await fetch(
        requestUrl, {
         method: 'PUT',
         headers: {
           'Authorization': toke
         },
         body: JSON.stringify(claimToSearch)
       }
       );
       const responseJSON = await requestResult.json();
       console.log("Claim Item Response: " + JSON.stringify(responseJSON))
 
       return responseJSON;
 
 

      } else {
        console.log('User didnt pass condition: ' + JSON.stringify(this.iClaim))
      }
    } catch (err) {
      console.log('error getting alinda:', err)
    }

  }


}