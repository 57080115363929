import * as React from 'react';
import { useAuthenticator, Menu, MenuItem, View, Divider, MenuButton} from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';


export const BuyMenu = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
    
  };

  const closeMenu = () => setIsOpen(false);
  const navigate = useNavigate();
  const { route } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);

  return (


    <View width="4rem">

      <Menu
        trigger={
          <MenuButton width="100%">
            Buy
          </MenuButton>
        }
        isOpen={isOpen}
        onOpenChange={handleOpenChange}
        width="3rem"
        maxWidth="4rem"
      >
        <MenuItem
          onClick={() => {

            closeMenu();
            navigate('/Buy')

          }}
        >
          Buy Book
        </MenuItem>

        <Divider />


        {route !== 'authenticated' ? (
            <MenuItem isDisabled >
              Glass - Must log in
              </MenuItem>
        ) : (
          <MenuItem


            onClick={() => {
              closeMenu();
              navigate('/Glass')
            }}
          >

            Glass Financials
          </MenuItem>
        )}
      </Menu>

    </View>

  );
};
