import { useAuthenticator, Menu, MenuItem, View, Divider, MenuButton } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from "../../contextAPI/context";
import { TypesContextReducers } from "../../contextAPI/reducers";
import { useContext, useState } from 'react';


export const ProfileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { state, dispatch } = useContext(AppContext);
  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
    // Do something else?
  };

  const closeMenu = () => setIsOpen(false);
  const navigate = useNavigate();
  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);

  const logOut = () => {
    signOut();
    dispatch({
      type: TypesContextReducers.UpdateUser,
      payload: {
        userName: "",
        userToken: "",
        hasClaimedBookGroup: false,
        isAdmin: false
      }
    });

    localStorage.setItem("Goochy.userName", "");
    navigate('/Home');
  }

  return (
    <View width="4rem">
      <Menu trigger={
        <MenuButton width="100%">
          Stuff
        </MenuButton>
      }
        isOpen={isOpen}
        onOpenChange={handleOpenChange}
        width="3rem"
        maxWidth="4rem"
      >
        <MenuItem
          onClick={() => {
            closeMenu();
            navigate('/Profile')
          }}
        >
          Profile
        </MenuItem>



        {route !== 'authenticated' ? (
          <MenuItem isDisabled >
            Join Crew - Must log in
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              closeMenu();
              navigate('/JoinHover')
            }}
          >
            Join the Hover
          </MenuItem>
        )}

        <Divider />
        <MenuItem
          onClick={() => {
            logOut();
            closeMenu();
          }}
        >
          Log Out
        </MenuItem>
      </Menu>
    </View>
  );
};
