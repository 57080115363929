// components/Home.js
import { Heading, View, TextField, Flex, Button, Text } from '@aws-amplify/ui-react';
import React, { useState } from 'react';
import { DDB_Item } from '../../DataServices/DDB/DDB_Item'
import { CustomCognitoUser } from '../../utils/CustomCognitoUser';


export function GiveBookAway(props: { cogUserString: string; }) {

  const GivenBySoldByClaimedBy = "GivenBy";//GivenBy, SoldBy, ClaimedBy
  const origin_book_id = "AlindaAndTheDayBetween";
  // const [giveAway, setGiveAway] = useState({item1:"", item2:""});
  const [giveAway, setGiveAway] = useState({
    cogUserString: props["cogUserString"],
    giveOrBuy: GivenBySoldByClaimedBy,
    origin_book_id: origin_book_id
  });

  const [CustomCogUser, setCustomCogUser] = useState({
    userEmail: "",
    userName: "",
    toke: "",
    isAdminGroup: false

  });

  const bookID = "AlindaAndTheDayBetween";


    // 👇️ type as React.ChangeEvent<HTMLInputElement>
  // or React.ChangeEvent<HTMLTextAreaElement> (for textarea elements)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
        setGiveAway(prevState => ({
      ...prevState,
      [name]: value
    }));
   // setMessage(event.target.value);
    console.log(`event name: ${event.target.value} event value: ${event.target.value}`);
  };


  const createBuyItem = async () => {
    try {

      if (props["cogUserString"]) {

        const cogUser = new CustomCognitoUser(props["cogUserString"])
        setCustomCogUser(cogUser);

        let ddbBuy = new DDB_Item(cogUser, giveAway.giveOrBuy);

        await ddbBuy.createBuyItemDB();


        return;
      }

    } catch (err) {
      console.log('error getting alinda:', err);

    }
  };


  const handleSubmit = () => {


    createBuyItem();
    console.log("On Submit: ");
    console.log("isAmin: " + CustomCogUser.isAdminGroup);
    console.log("User Name: " + CustomCogUser.userName);
    console.log("User Token: " + CustomCogUser.toke);


  };

  return (
    <>
      <Heading level={4}>
        Give a book away!!!!!
      </Heading>
      <View>
        Hummmmmmmmmmmm
        <Flex direction="column" gap="1rem">
          <Flex>
            <Heading level={5}>
              Giver Name:
            </Heading>
            <Text> </Text>
          </Flex>
          <Flex>
            <Heading level={5}>
              Giver Token:
            </Heading>
            <Text></Text>
          </Flex>
          <Flex>
            <Heading level={5}>
              origin_book_id: <Text>{bookID}</Text>
            </Heading>
          </Flex>

          <Flex>
            <Button onClick={() => handleSubmit()}>Submit</Button>
          </Flex>
        </Flex>
      </View>
    </>
  );
}