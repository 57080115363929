import { config as appConfig } from "../../configParams";
import { CustomCognitoUser } from "../../utils/CustomCognitoUser"


export class DDB_User {
  //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Classes

  T_PK = "";
  T_SK = "";
  X1_PK: string;
  date_first_login: string | undefined;
  date_last_login: string | undefined;
  number_of_logins = 0;
  userEmail = "";
  userName = "";

  role: string | undefined;
  registered_buy_id = "";
  profile_comment: string | undefined;
  toke: string;
  isAdminGroup: boolean;
  hasClaimedBookGroup: boolean;


  constructor(CognitoUserString: string, X1_PK: string) {

    const cogUser = new CustomCognitoUser(CognitoUserString);
    this.X1_PK = X1_PK;

    this.isAdminGroup = cogUser.isAdminGroup;
    this.hasClaimedBookGroup = cogUser.hasClaimedBookGroup;

    this.userName = cogUser.userName;
    this.userEmail = cogUser.userEmail;
    this.toke = cogUser.toke;


    this.T_PK = "User";
    this.T_SK = "User#" + this.userName;

  }

  async getUserDB() {
    try {


      if (this.userName && this.toke) {
        const requestUrl =
          `${appConfig.api.alindaUserLogin}?userName=${this.userName}`;

        console.log("toke: " + this.toke);
        console.log("requestUrl: " + requestUrl);

        const requestResult = await fetch(requestUrl, {
          method: "GET",
          headers: {
            Authorization: this.toke,
          }
        });

        const responseJSON = await requestResult.json();
        console.log("responseJSON: " + JSON.stringify(responseJSON))

        this.date_first_login = responseJSON.Item.date_first_login;


      }
    } catch (err) {
      console.log('error getting alinda:', err)

    }
  }


  async updateUserDB() {
    try {

      const userDBToUpdate = {
        T_PK: this.T_PK,
        T_SK: this.T_SK,
        X1_PK: this.X1_PK,
        userName: this.userName,
        isAdmin: this.isAdminGroup,
        hasClaimedBookGroup: this.hasClaimedBookGroup,
        userEmail: this.userEmail,


      }


      if (userDBToUpdate && userDBToUpdate.T_PK && userDBToUpdate.T_SK && this.toke) {

        const userLoginURL = appConfig.api.alindaUserLogin;
        console.log(`requestUrl: ${userLoginURL}?logIn=${userDBToUpdate.userName}`)
        console.log(`userDBToUpdate: ${JSON.stringify(userDBToUpdate)}`)
        console.log(`this.toke: ${JSON.stringify(this.toke)}`)

        const requestUrl = `${appConfig.api.alindaUserLogin}?logIn=${userDBToUpdate.userName}`;
        const requestResult = await fetch(
          requestUrl, {
          method: 'PUT',
          headers: {
            'Authorization': this.toke
          },
          body: JSON.stringify(userDBToUpdate)
        }
        );
        const responseJSON = await requestResult.json();


        return responseJSON;
      } else {
        console.log('User didnt pass condition: ' + JSON.stringify(userDBToUpdate))
      }
    } catch (err) {
      console.log('error getting alinda:', err)
    }

  }


}