type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
  ? {
    type: Key;
  }
  : {
    type: Key;
    payload: M[Key];
  }
};

export enum TypesContextReducers {
  Create = "CREATE_PRODUCT",
  Delete = "DELETE_PRODUCT",
  Add = "ADD_PRODUCT",
  UpdateUser = "UPDATE_USER",
}

// Product

type ProductType = {
  id: number;
  name: string;
  price: number;
};

type UserType = {
  userName: string;
  isAdmin: boolean;
  hasClaimedBookGroup: boolean;
  userToken: string;
};

type ProductPayload = {
  [TypesContextReducers.Create]: {
    id: number;
    name: string;
    price: number;
  };
  [TypesContextReducers.Delete]: {
    id: number;
  };
};

type UserPayload = {
  [TypesContextReducers.UpdateUser]: {
    userName: string;
    isAdmin: boolean;
    hasClaimedBookGroup: boolean;
    userToken: string;
  };
}



export type ProductActions = ActionMap<ProductPayload>[keyof ActionMap<
  ProductPayload
>];

export type UserActions = ActionMap<UserPayload>[keyof ActionMap<
  UserPayload
>];

export const productReducer = (
  state: ProductType[],
  action: ProductActions | ShoppingCartActions | UserActions
) => {
  switch (action.type) {
    case TypesContextReducers.Create:
      return [
        ...state,
        {
          id: action.payload.id,
          name: action.payload.name,
          price: action.payload.price
        }
      ];
    case TypesContextReducers.Delete:
      return [...state.filter(product => product.id !== action.payload.id)];
    default:
      return state;
  }
};

export const userReducer = (
  state: UserType,
  action: UserActions | ShoppingCartActions | ProductActions
) => {
  switch (action.type) {
    case TypesContextReducers.UpdateUser:
      state.isAdmin = action.payload.isAdmin;
      state.hasClaimedBookGroup = action.payload.hasClaimedBookGroup;
      state.userName = action.payload.userName;
      state.userToken = action.payload.userToken;
      return state;
    default:
      return state;
  }

};

// ShoppingCart

type ShoppingCartPayload = {
  [TypesContextReducers.Add]: undefined;
};

export type ShoppingCartActions = ActionMap<
  ShoppingCartPayload
>[keyof ActionMap<ShoppingCartPayload>];

export const shoppingCartReducer = (
  state: number,
  action: ProductActions | ShoppingCartActions | UserActions
) => {
  switch (action.type) {
    case TypesContextReducers.Add:
      return state + 1;
    default:
      return state;
  }
};
