// components/Home.js
import { useAuthenticator, Heading, View, Text } from '@aws-amplify/ui-react';
import { useContext } from 'react';
import { resourceLimits } from 'worker_threads';
import { AppContext } from '../../contextAPI/context';


export const Profile = () => {

  const { route } = useAuthenticator((context) => [context.route]);
  const { user } = useAuthenticator((context) => [context.user]);
  const { state, dispatch } = useContext(AppContext);

  const message =
    route === 'authenticated'
      ? `Hi ${user.username}`
      : 'Login Please';

  const cogUserName = user.username;

  const giveAlindaAndTheDayBetween  = (): string  =>  {
    let result = "Well, not much else here - yet";


    return result;

  }

  return (

    <>
      <View>
        {route !== 'authenticated' ? (

          <Heading level={5}>{message}</Heading>
        ) : (
          <>
            <Heading level={5}>{message}</Heading>
            <View>Registered Email:  {user.attributes?.email}</View>
            <Text>{giveAlindaAndTheDayBetween()}</Text>
          </>
        )}

      </View>

    </>
  );
}