import { Authenticator } from '@aws-amplify/ui-react';
import { AppProvider } from "./contextAPI/context";
import { Community } from './components/Community/Community';
import { Login } from './components/Login/Login';
import { Friends } from './components/Friends/Friends';
import { Home } from './components/Home/Home';
import { Buy } from './components/Buy/Buy';
import { Layout } from './components/NavLayout/Layout';
import { MyBooks } from './components/MyBooks/MyBooks';
import { TempGlass } from './components/Buy/Glass/TempGlass'
import { BuyMenu } from './components/Buy/BuyMenu'
import { ProfileMenu } from './components/Stuff/ProfileMenu'
import { Profile } from './components/Stuff/Profile'
import { JoinHover  } from './components/Stuff/JoinHover'

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';


const MyRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Buy />} />
          <Route path="/Home" element={<Home />} />
          {/* <Route path="/Community" element={<Community />} /> */}
          {/* <Route path="/Friends" element={<Friends />} /> */}
          {/* <Route path="/MyBooks" element={<MyBooks />} /> */}
          <Route path="/buy" element={<Buy />} />
          <Route path="/Glass" element={<TempGlass />} />
          <Route path="/BuyMenu" element={<BuyMenu />} />
          <Route path="/ProfileMenu" element={<ProfileMenu />} />
          <Route path="/login" element={<Login />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/JoinHover" element={<JoinHover />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const App = () => {
  return (
    <Authenticator.Provider>
      <AppProvider>
        <MyRoutes />
      </AppProvider>

    </Authenticator.Provider>
  );
}

export default App;

