


export const schemaItemIdConstants = {

}

export enum schemaUserEnum_X1_PK {
    User = "User",
    Fav = "Fav",
    Claim = "Claim"
  }

export const schemaUserConstants = {



}

export interface attsItemCreation {
  T_PK: string, T_SK: string, 
  X1_PK: string, X1_SK: string,
  X2_PK: string, X2_SK: string,
  X3_PK: string, X3_SK: string, 
  X4_PK?: string, X4_SK?: string, //optional, only use if have valu
  sent_type: string
}