// components/Home.js
import { Button, Divider, Flex, Heading, TextField, useAuthenticator, View } from '@aws-amplify/ui-react';
import { DDB_ClaimItem } from '../../DataServices/DDB/DDB_Claim_Item'
import { FormInputGeneral } from '../Forms/InputGeneral'


export const JoinHover = () => {

  const { user } = useAuthenticator((context) => [context.user]);

  const { value: itemID, bind: bindItemID, reset: resetItemID } = FormInputGeneral('');
  const { value: glassID, bind: bindGlassID, reset: resetGlassName } = FormInputGeneral('');

  const validateIDs = async (evt: { preventDefault: () => void; }) => {
    evt.preventDefault();

    let ddbClaimItem = new DDB_ClaimItem(
      JSON.stringify(user),
      itemID,
      glassID
    );



    await ddbClaimItem.createClaim();
    resetItemID();
    resetGlassName();
  }


  return (

    <>
      <Heading level={4}>
        Item and Glass IDs are found on the back of books that were purchased via the: 
      </Heading>
      <Heading level={5}>
      no-delivery-estimate-direct-from-owner-that-personally-mails-it 
      </Heading>
      <br/>
      <View>
      Now, not much happens when you do make a claim - yet
      </View>
      <br/>
      {/* <Divider orientation="horizontal" /> */}
        <br/>
      <TextField
        placeholder="Input your Item Id (found back of book)"
        label="Item Id:"
        type="text" {...bindItemID}
        size="large"
        errorMessage="There is an error" />

      <TextField

        placeholder="Input in your Glass Id"
        label="Glass Id"
        type="text" {...bindGlassID}
        size="large"
        errorMessage="There is an error" />
<br/>
      <Button onClick={validateIDs} >Claim Item</Button>

    </>
  );
}