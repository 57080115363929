// components/Home.js
import { useAuthenticator, Heading, View, Text } from '@aws-amplify/ui-react';
import { useContext } from 'react';
import { AppContext } from '../../../contextAPI/context';


export const TempGlass = () => {

  const { route } = useAuthenticator((context) => [context.route]);
  const { user } = useAuthenticator((context) => [context.user]);
  const { state, dispatch } = useContext(AppContext);

  const message =
    route === 'authenticated'
      ? `Hi ${user.username}`
      : 'Login Please';

  const cogUserName = user.username;

  const giveAlindaAndTheDayBetween  = (): string  =>  {
    let result = "mode";


    return result;

  }

  return (

    <>
      <View>
        {route !== 'authenticated' ? (

          <Heading level={5}>{message}</Heading>
        ) : (
          <>
            <Heading level={5}>{message}</Heading>
            <br/>
            <View>This will eventually show financial details, firstly how much money has been colected for a good cause, when a book is purchased through the: </View>
            <View>no-delivery-estimate-direct-from-owner-that-personally-mails-it </View>
            <Text>{giveAlindaAndTheDayBetween()}</Text>
            <Text>But there ain't nothing happening - yet</Text>
          </>
        )}

      </View>

    </>
  );
}