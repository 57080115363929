// components/Layout.js
import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuthenticator, Button, View, Flex } from '@aws-amplify/ui-react';
import { ProfileMenu } from '../Stuff/ProfileMenu';
import { BuyMenu } from '../Buy/BuyMenu'


export const Layout = () => {

  const { route } = useAuthenticator((context) => [
    context.route,
    context.signOut
      ]);

  const navigate = useNavigate();



  return (
    <>
      <nav>

        <Flex direction={{ base: 'column', large: 'row' }}>

          {/* <Button width="100%"
            onClick={() => navigate('/Community')}>
            Community
          </Button> */}

          {/* <Button width="100%"
            onClick={() => navigate('/Friends')}>
            Friends
          </Button> */}

          {/* <Button width="100%"
            onClick={() => navigate('/MyBooks')}>
            My Books
          </Button> */}

        </Flex>

        <Flex direction={{ base: 'column', large: 'row' }}>
          
          <Button fontSize="medium" size="small" onClick={() => navigate('/Home')}>Home</Button>

          <BuyMenu />

          {route !== 'authenticated' ? (
            <Button  onClick={() => navigate('/login')}>Login</Button>
          ) : (
            <ProfileMenu />
          )}
        </Flex>

      </nav>
      
      <View >
        {route === 'authenticated' ? '' : 'Please Login or Create and Account!'}
      </View>

      <Outlet />
    </>
  );
}