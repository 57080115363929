import { config } from '../configParams'


export class CustomCognitoUser {

    userEmail = "";
    userName = "";
    toke: string;
    isAdminGroup: boolean;
    hasClaimedBookGroup: boolean;



    constructor(CognitoUserString: string) {

        const cogUser = JSON.parse(CognitoUserString);


        this.isAdminGroup = false;
        this.hasClaimedBookGroup = false;
    
        if (cogUser.signInUserSession.accessToken.payload["cognito:groups"] 
          && cogUser.signInUserSession.accessToken.payload["cognito:groups"].length > 0)
          {
            cogUser.signInUserSession.accessToken.payload["cognito:groups"].forEach((group: string) => {

              if(group === config.USER_COG_GROUP_NAME_ADMIN) {
                this.isAdminGroup = true;
              }
            });
        }
    
        this.userName  = cogUser.username ? cogUser.username : "";
        this.userEmail = cogUser.attributes?.email ? cogUser.attributes?.email : "";
        this.toke = cogUser.signInUserSession.idToken.jwtToken ? cogUser.signInUserSession.idToken.jwtToken : "";


    }


}